import HttpFactory from "../factory";
import type {ColumnFilter} from "@tanstack/vue-table";

class BusinessCaseIssueModule extends HttpFactory {
  async listBusinessCaseIssues(pageNumber: number, pageSize: number, sort: string, filters: ColumnFilter[]) {
    return await this.call("api/business-case/issues-list", "post", {
      query: {
        page: pageNumber,
        page_size: pageSize,
        sort
      }
    },
    {filters: filters});
  }

  async listBusinessCaseIssue(id: string, pageNumber: number, pageSize: number, sort: string, filters: ColumnFilter[]) {
    return await this.call("api/business-case/issue-list/{id}", "post", {
      path: {id},
      query: {
        page: pageNumber,
        page_size: pageSize,
        sort
      }
    },
    {filters: filters});
  }

  async businessCaseIssueDetail(id: string) {
    return await this.call("api/business-case/issue-detail/{id}", "get", {
      path: {id}
    });
  }

  async updateBusinessCaseIssue(id: string, data: APIRequestBody<"api/business-case/update-issue-detail/{id}", "patch">) {
    return await this.call("api/business-case/update-issue-detail/{id}", "patch", {
      path: {id}
    },
    {
      ...data
    });
  }

  async deleteBusinessCaseIssues(ids: string[]) {
    return await this.call("api/business-case/issue-delete", "delete", {
      path: {ids}
    });
  }

  async businessCasesIssueStatesList() {
    return await this.call("api/business-case/issue-states/list", "get");
  }

  async businessCaseIssueHistory(id: string, pageNumber: number, pageSize: number, sort: string, filters: ColumnFilter[]) {
    return await this.call("api/business-case/issue-history/grid/{id}", "post", {
      path: {id},
      query: {
        page: pageNumber,
        page_size: pageSize,
        sort
      }
    },
    {filters: filters});
  }

  async newBusinessCaseIssue(formData: FormData) {
    return await this.call("api/business-case/new-business-case-issue", "post", {}, formData);
  }

  async sendBusinessCaseIssueProblemEmail(data: { issue_type: string; user_email: string }) {
    return await this.call("api/business-case/send-business-case-issue-problem-email", "post", {}, data);
  }

  async sendUploadedFilesForValidation(formData: FormData) {
    return await this.call("api/business-case/validate-uploaded-files", "post", {}, formData);
  }

  async setReturnedDeviceQuality(formData: FormData) {
    return await this.call("api/business-case/set-returned-device-quality", "put", {}, formData);
  }

  async setChangedState(id: string, data: APIRequestBody<"api/business-case/update-issue-detail/{id}", "patch">){
    return await this.call("api/business-case/issue/change-state/{id}", "patch", {path: {id}}, {...data});
  }

  async setToEvaluation(data: APIRequestBody<"api/business-case/update-issue-detail/{id}", "patch">) {
    return await this.call("api/business-case/issue/set-to-evaluation", "patch", {}, {...data});
  }

  async manageReclamation(id: string, data: APIRequestBody<"api/business-case/issue/reject-reclamation/{id}", "patch">) {
    return await this.call("api/business-case/issue/manage-reclamation/{id}", "patch", {path: {id}}, {...data});
  }

  async changeDevice(businessCaseIssueId: string, masterDeviceId: string, colorId: string) {
    return await this.call("/api/business-case/issue/{id}/device/{master_device_id}/{color_id}", "post", {
      path: {
        id: businessCaseIssueId,
        master_device_id: masterDeviceId,
        color_id: colorId
      }
    });
  }

  async searchMasterDevices(businessCaseIssueId: string, searchValue: string) {
    return await this.call("/api/business-case/issue/master-devices/{business_case_issue_id}/search", "get", {
      path: {business_case_issue_id: businessCaseIssueId},
      query: {q: searchValue}
    });
  }

  async cancelChangeDevice(businessCaseIssueId: string) {
    return await this.call("/api/business-case/issue/{business_case_issue_id}/cancel-device-change", "post", {path: {business_case_issue_id: businessCaseIssueId}});
  }

  async validateDeliveryAddress(data: {
    delivery_type: string;
    pickup_address?: {
      street_and_number: string;
      city: string;
      postal_code: string;
      country: string;
      was_address_confirmed: boolean;
    };
  }) {
    return await this.call("api/business-case/business-case-address-validation", "post", {}, data);
  }

  async getBusinessCaseIssues(businessCaseId: string, pageNumber: number, pageSize: number, sort: string, filters: ColumnFilter[]) {
    return await this.call("api/business-case/get-business-case-issues/{business_case_id}", "post", {
      path: {business_case_id: businessCaseId},
      query: {
        page: pageNumber,
        page_size: pageSize,
        sort
      }
    },
    {filters: filters});
  }

  async printPaymentReceipt(businessCaseIssueId: string) {
    return await this.call("/api/business-case/issue/{businessCaseIssueId}/payment-receipt", "post", {
      path: {businessCaseIssueId}
    });
  }

  async printHandoverProtocol(businessCaseIssueId: string, store: string) {
    return await this.call("/api/business-case/issue/{businessCaseIssueId}/handover-protocol", "post", {
      path: {businessCaseIssueId}
    }, {store});
  }

  async emailsList(id: string, pageNumber: number, pageSize: number, sort: string, filters: ColumnFilter[]) {
    return await this.call("api/business-case/issue/{id}/emails", "post", {
      path: {id},
      query: {
        page: pageNumber,
        page_size: pageSize,
        sort
      }
    },
    {filters: filters});
  }

  async resendEmails(businessCaseIssueID: string, ids: string) {
    return await this.call("/api/business-case/issue/{id}/resend-emails", "post", {path: {id: businessCaseIssueID}, query: {ids}});
  }

}


export default BusinessCaseIssueModule;
