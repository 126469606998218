import { h } from "vue";
import type { IconSet, IconProps } from "vuetify";
import { filename } from 'pathe/utils'

const icons = Object.fromEntries(
  // @ts-ignore
  Object.entries(import.meta.glob('./assets/svg/samsung/*.svg', { eager: true })).map(([key, value]) => [filename(key), value.default])
)

export const iconSetSamsung: IconSet = {
  component: (props: IconProps) => h(
    props.tag,
    {},
    [h(icons?.[props.icon as string] || { render: () => "" }, { class: `icon icon--${props.icon}` })]
  )
};
