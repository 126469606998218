import validate from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.17_db0@0.3.1_eslint@9.23.0_jiti@2.4_79fe5d09dd4163cece4df3e077d2b5a4/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/app/services/frontend/src/middleware/auth.global.ts";
import manifest_45route_45rule from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.17_db0@0.3.1_eslint@9.23.0_jiti@2.4_79fe5d09dd4163cece4df3e077d2b5a4/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "has-parent-partner": () => import("/app/services/frontend/src/middleware/has_parent_partner.ts"),
  "is-manufacturer-partner": () => import("/app/services/frontend/src/middleware/is_manufacturer_partner.ts"),
  "is-partner": () => import("/app/services/frontend/src/middleware/is_partner.ts"),
  "is-premium-partner": () => import("/app/services/frontend/src/middleware/is_premium_partner.ts")
}