import { defineStore, acceptHMRUpdate } from "pinia";

const useAuthStore = defineStore("ct:auth", () => {
  const localePath = useLocalePath();
  const runtimeConfig = useRuntimeConfig();
  const appConfig = useAppConfig();
  const { TTL_DAY } = useTTL();
  const nuxtApp = useNuxtApp();
  const route = useRoute();

  const isLoggedIn = ref<boolean>(false);

  const data = useState<SessionData | null>("auth:data", () => null);
  const getReferralLink = computed<string>(() => {
    if (data.value?.id) {
      let path = "";
      if(data.value?.partner?.use_new_device_process){
        path = localePath({ name: "bonus_partner-buyout-new_device_type-new_meta_master-new_variant-new_color-type-manufacturer-meta_master-variant-color-quality", params: {bonus_partner: data.value.partner?.parent ? data.value.partner.parent.seo_name : data.value.partner.seo_name }, query: { refId: data.value.reference_code }});
      } else {
        path = (localePath({ name: "buyout-type-manufacturer-meta_master-variant-color-quality", query: { refId: data.value.reference_code } }));
      }
      return `${window.location.origin}/${runtimeConfig.public.region}${path}`;
    }
    return "";
  }
  );
  const hasInitialSession = computed<boolean>(() => !!data.value);
  const lastRefreshedAt = useState<Date | null>("auth:lastRefreshedAt", () => {
    if (hasInitialSession.value) {
      return /* @__PURE__ */ new Date();
    }
    return null;
  });
  const loading = useState<boolean>("auth:loading", () => false);
  const status = computed<string>(() => {
    if (loading.value) {
      return "loading";
    } else if (data.value) {
      return "authenticated";
    } else {
      return "unauthenticated";
    }
  });
  watch(status, (authenticated) => {
    isLoggedIn.value = authenticated === "authenticated";
  });
  const _accessTokenCookie = useCookie<string | null>(`auth:${appConfig.appName}:${runtimeConfig.public.region}:access-token`, { default: () => null, maxAge: 7 * TTL_DAY / 1000, sameSite: "lax" });
  const accessToken = useState<string | null>(`auth:${appConfig.appName}:${runtimeConfig.public.region}:access-token`, () => _accessTokenCookie.value);

  const accessTokenHeader = computed<string | null>(() => {
    if (accessToken.value === null) {
      return null;
    }
    return `Bearer ${accessToken.value}`;
  });

  const isPartner = computed<boolean>(() => {
    return data.value?.role === "partner";
  });

  const isPartnerBrandStore = computed<boolean>(() => {
    return !!(isPartner.value && data.value?.ambassador_store?.is_brand_store);
  });

  const isPremiumPartner = computed<boolean>(() => {
    return data.value?.is_premium_partner || false;
  });

  const isO2Partner = computed<boolean>(() => {
    return (isPartner.value && data.value?.partner.seo_name.toUpperCase() === "O2");
  });

  const isOrangePartner = computed<boolean>(() => {
    return (isPartner.value && data.value?.partner.seo_name.toUpperCase() === "ORANGE");
  });

  const isBasicUser = computed<boolean>(() => {
    return (data.value?.role === "user" && data.value?.partner === null) ;
  });

  const areRewardsAvailable = computed<boolean>(() => {
    return (isPartner.value && data.value?.partner.rewards_available) || false;
  });

  function parseJwt (token: string) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(atob(base64).split("").map(function (c) {
      return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(""));
    return JSON.parse(jsonPayload);
  }

  const _refreshTokenCookie = useCookie<string | null>(`auth:${appConfig.appName}:${runtimeConfig.public.region}:refresh-token`, { default: () => null, maxAge: 2 * TTL_DAY / 1000, sameSite: "lax" });
  const refreshToken = useState<string | null>(`auth:${appConfig.appName}:${runtimeConfig.public.region}:refresh-token`, () => _refreshTokenCookie.value);

  watch(refreshToken, async (value) => {
    if (value !== null && value !== _refreshTokenCookie.value) {
      await nextTick();
      _refreshTokenCookie.value = value;
    }
  });

  function isAccessTokenExpired (): boolean {
    if (accessToken.value === null || accessToken.value === undefined) { return true; }
    return (Date.now() >= parseJwt(accessToken.value).exp * 1000);
  }

  let refreshTokenPromise: Promise<any> | null = null;

  async function refreshAccessToken (): Promise<void> {
    if (!refreshTokenPromise && refreshToken.value) {
      refreshTokenPromise = nuxtApp.$api.auth.refreshToken(refreshToken.value).then(async ({ data: refreshData, error}) => {
        if (refreshData.value) {
          accessToken.value = refreshData.value.access;
          _accessTokenCookie.value = refreshData.value.access;
          if (!isLoggedIn.value) {
            await getSession();
            isLoggedIn.value = true;
          }
        }

        if (error.value?.statusCode === 401) {
          await internalLogout();
          navigateTo(useLocalePath()({ name: "login", query: { next: route.path } }));
        }

      }).finally(() => {
        refreshTokenPromise = null;
      });
    }

    return refreshTokenPromise;
  }

  let getSessionPromise: Promise<any> | null = null;

  async function getSession () {
    if (!getSessionPromise && accessTokenHeader.value) {
      getSessionPromise = nuxtApp.$api.auth.session().then(({ data: sessionData, error }) => {
        if (sessionData.value) {
          data.value = sessionData.value;
          nuxtApp.$sentrySetUser({ id: sessionData.value.id, username: sessionData.value.username, email: sessionData.value.email });
        }
        if (error.value) {
          data.value = null;
          accessToken.value = null;
          _accessTokenCookie.value = null;
        }
      }).finally(() => {
        getSessionPromise = null;
      });
    }
    return getSessionPromise;
  }

  async function internalLogout () {
    isLoggedIn.value = false;
    await nextTick();
    accessToken.value = null;
    _accessTokenCookie.value = null;
    data.value = null;
    refreshToken.value = null;
    _refreshTokenCookie.value = null;
    await nextTick();
    nuxtApp.$sentrySetUser(null);
  }

  /**
   * This function must be used instead of useAuth().signOut() because the user's refresh token must be
   * sent to be blacklisted.
   */
  async function logout (name: string = "index", param: string | null = null) {
    if (accessToken.value !== null) {
      await nuxtApp.$api.auth.logout(refreshToken.value as string);
    }
    await internalLogout();
    await nextTick();

    if (param && name=="partner") {
      navigateTo(localePath({name: name, params: { partner: param }}));
    } else {
      navigateTo(localePath({ name: name }));
    }
  }

  function hasPermission (permission: string): boolean {
    if (!data.value) { return false; }
    return data.value.permissions.includes(permission);
  }

  const rememberMeTokenCookie = useCookie<string | null>(`auth:${appConfig.appName}:${runtimeConfig.public.region}:remember-me-token`, { default: () => null, maxAge: 180 * TTL_DAY / 1000, sameSite: "lax" });

  return {
    refreshToken,
    data,
    lastRefreshedAt,
    isLoggedIn,
    loading,
    status,
    accessToken,
    _accessTokenCookie,
    accessTokenHeader,
    isPartner,
    isPartnerBrandStore,
    isPremiumPartner,
    isAccessTokenExpired,
    refreshAccessToken,
    logout,
    getSession,
    internalLogout,
    getReferralLink,
    hasPermission,
    isO2Partner,
    isOrangePartner,
    isBasicUser,
    areRewardsAvailable,
    rememberMeTokenCookie
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot));
}

export default useAuthStore;
