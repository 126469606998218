<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup lang="ts">
const runtimeConfig = useRuntimeConfig();

useHead({
  link: [
    { rel: "apple-touch-icon", sizes: "180x180", href: `${runtimeConfig.public.baseUrl}/apple-icon.png?v=1.01` },
    { rel: "icon", type: "image/png", sizes: "32x32", href: `${runtimeConfig.public.baseUrl}/favicon-32x32.png?v=1.01` },
    { rel: "icon", type: "image/png", sizes: "16x16", href: `${runtimeConfig.public.baseUrl}/favicon-16x16.png?v=1.01` },
    { rel: "mask-icon", href: `${runtimeConfig.public.baseUrl}/safari-pinned-tab.svg?v=1.01`, color: "#f10375" },
    { rel: "shortcut icon", href: `${runtimeConfig.public.baseUrl}/favicon.ico?v=1.01` }
  ]
});
</script>
