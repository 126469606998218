import { defineNuxtPlugin } from "#app";
import AuthModule from "../repository/modules/auth";
import type {$Fetch, FetchOptions} from "ofetch";
import UserModule from "../repository/modules/user";
import ProductModule from "../repository/modules/product";
import InstallmentsModule from "../repository/modules/installments";
import BlackListModule from "../repository/modules/blacklist";
import BuyoutModule from "../repository/modules/buyout";
import ChatModule from "../repository/modules/chat";
import CommonModule from "../repository/modules/common";
import ExportModule from "../repository/modules/export";
import FaqModule from "../repository/modules/faq";
import PaymentModule from "../repository/modules/payment";
import LogModule from "../repository/modules/log";
import CartModule from "../repository/modules/cart";
import ClientZoneModule from "../repository/modules/clientZone";
import StoreModule from "../repository/modules/store";
import BusinessCaseModule from "../repository/modules/businessCase";
import BusinessCaseIssueModule from "../repository/modules/businessCaseIssues";
import CatalogueModule from "../repository/modules/catalogue";
import DeliveryModule from "../repository/modules/delivery";

/** ApiInstance interface provides us with good typing */
declare global {
  interface IApiInstance {
    auth: AuthModule
    blackList: BlackListModule
    buyout: BuyoutModule
    cart: CartModule
    catalogue: CatalogueModule
    chat: ChatModule
    clientZone: ClientZoneModule
    common: CommonModule
    export: ExportModule
    faq: FaqModule
    installments: InstallmentsModule
    log: LogModule
    payment: PaymentModule
    product: ProductModule
    store: StoreModule
    user: UserModule
    businessCase: BusinessCaseModule
    businessCaseIssue: BusinessCaseIssueModule
    delivery: DeliveryModule
  }
}

export default defineNuxtPlugin((nuxtApp) => {

  const { $i18n, $pinia } = useNuxtApp();
  const authStore = useAuthStore($pinia);
  const localePath = useLocalePath();
  const locale = $i18n.locale.value;
  const runtimeConfig = useRuntimeConfig();

  const fetchOptions: FetchOptions = {
    baseURL: import.meta.client ? nuxtApp.$config.public.baseApiUrl as string : "http://api:8000",
    async onRequest({options}) {
      if (!(options.headers instanceof Headers)) {
        options.headers = new Headers(options.headers);
      }

      options.headers?.set("Accept-Language", locale);
      options.headers?.set("accept", "application/json");
      options.headers?.set("X-Region", runtimeConfig.public.region);

      //@ts-ignore
      if(!(options?.skipTokenCheck ?? false)) {
        if (authStore.isAccessTokenExpired()) {
          await authStore.refreshAccessToken();
        }
      }
      if (authStore.accessToken) {
        options.headers?.set("Authorization", `Bearer ${authStore.accessToken}`);
      }
    },
    async onResponseError({ response }) {
      if (response.status === 401) {
        await authStore.internalLogout();
        await navigateTo(localePath({name: "login"}));
      }
    }
  };

  /** create a new instance of $fetcher with a custom option */
  const apiFetcher = $fetch.create(fetchOptions);

  /** an object containing all repositories we need to expose */
  const modules: IApiInstance = {
    auth: new AuthModule(apiFetcher as $Fetch),
    blackList: new BlackListModule(apiFetcher as $Fetch),
    buyout: new BuyoutModule(apiFetcher as $Fetch),
    cart: new CartModule(apiFetcher as $Fetch),
    catalogue: new CatalogueModule(apiFetcher as $Fetch),
    chat: new ChatModule(apiFetcher as $Fetch),
    clientZone: new ClientZoneModule(apiFetcher as $Fetch),
    common: new CommonModule(apiFetcher as $Fetch),
    export: new ExportModule(apiFetcher as $Fetch),
    faq: new FaqModule(apiFetcher as $Fetch),
    installments: new InstallmentsModule(apiFetcher as $Fetch),
    log: new LogModule(apiFetcher as $Fetch),
    payment: new PaymentModule(apiFetcher as $Fetch),
    product: new ProductModule(apiFetcher as $Fetch),
    store: new StoreModule(apiFetcher as $Fetch),
    user: new UserModule(apiFetcher as $Fetch),
    businessCase: new BusinessCaseModule(apiFetcher as $Fetch),
    businessCaseIssue: new BusinessCaseIssueModule(apiFetcher as $Fetch),
    delivery: new DeliveryModule(apiFetcher as $Fetch)
  };

  return {
    provide: {
      api: modules,
    },
  };
});
