
// @ts-nocheck


export const localeCodes =  [
  "en",
  "sk"
]

export const localeLoaders = {
  en: [
    {
      key: "locale_en_46json_b40de7e8",
      load: () => import("#nuxt-i18n/b40de7e8" /* webpackChunkName: "locale_en_46json_b40de7e8" */),
      cache: true
    }
  ],
  sk: [
    {
      key: "locale_sk_46json_1887a51b",
      load: () => import("#nuxt-i18n/1887a51b" /* webpackChunkName: "locale_sk_46json_1887a51b" */),
      cache: true
    }
  ]
}

export const vueI18nConfigs = [
  () => import("#nuxt-i18n/d4dbc1e6" /* webpackChunkName: "config_i18n_46config_46ts_d4dbc1e6" */)
]

export const nuxtI18nOptions = {
  restructureDir: "i18n",
  experimental: {
    localeDetector: "",
    switchLocalePathLinkSSR: false,
    autoImportTranslationFunctions: false,
    typedPages: true,
    typedOptionsAndMessages: false,
    generatedLocaleFilePathFormat: "absolute",
    alternateLinkCanonicalQueries: false,
    hmr: true
  },
  bundle: {
    compositionOnly: true,
    runtimeOnly: false,
    fullInstall: true,
    dropMessageCompiler: false,
    optimizeTranslationDirective: true
  },
  compilation: {
    strictMessage: false,
    escapeHtml: false
  },
  customBlocks: {
    defaultSFCLang: "json",
    globalSFCScope: false
  },
  locales: [
    {
      code: "en",
      name: "English",
      format: "en-GB",
      language: "en",
      files: [
        "/app/services/frontend/i18n/lang/en.json"
      ]
    },
    {
      code: "sk",
      name: "Slovensky",
      format: "sk-SK",
      language: "sk",
      files: [
        "/app/services/frontend/i18n/lang/sk.json"
      ]
    }
  ],
  defaultLocale: "sk",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  trailingSlash: false,
  defaultLocaleRouteNameSuffix: "default",
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "../i18n/lang",
  rootRedirect: undefined,
  detectBrowserLanguage: {
    alwaysRedirect: false,
    cookieCrossOrigin: false,
    cookieDomain: null,
    cookieKey: "i18n_redirected",
    cookieSecure: false,
    fallbackLocale: "",
    redirectOn: "root",
    useCookie: true
  },
  differentDomains: false,
  baseUrl: "",
  customRoutes: "page",
  pages: {},
  skipSettingLocaleOnNavigate: false,
  types: "composition",
  debug: false,
  parallelPlugin: false,
  multiDomainLocales: false,
  i18nModules: []
}

export const normalizedLocales = [
  {
    code: "en",
    name: "English",
    format: "en-GB",
    language: "en",
    files: [
      {
        path: "/app/services/frontend/i18n/lang/en.json",
        cache: undefined
      }
    ]
  },
  {
    code: "sk",
    name: "Slovensky",
    format: "sk-SK",
    language: "sk",
    files: [
      {
        path: "/app/services/frontend/i18n/lang/sk.json",
        cache: undefined
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false
export const hasPages = true

export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18nInternal"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
/** client **/

/** client-end **/