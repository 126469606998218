import payload_plugin_l0I11Bt9_F06hAe1qG6_nrqSsvHyaGwuipYRvRr3oPA from "/app/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.8.2_vue@3.5.13_typescript@5.8.2__/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_UxWlz5y9wvp1gUTDNaVZlh3xGqCt_C2BaE_5DL_F6x0 from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.17_db0@0.3.1_eslint@9.23.0_jiti@2.4_79fe5d09dd4163cece4df3e077d2b5a4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_jvMn9V86843ECJOzea8j_7ex_2KkTyMc6IWSosUTaHU from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.17_db0@0.3.1_eslint@9.23.0_jiti@2.4_79fe5d09dd4163cece4df3e077d2b5a4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_WzvjD5gTwmbF2KnmezE_fYaY6DPmgF8GP7_9rDADhZ4 from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.17_db0@0.3.1_eslint@9.23.0_jiti@2.4_79fe5d09dd4163cece4df3e077d2b5a4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_5_Df5MibenU9q7hOWAlwbNrMpfjqEZ03E35FowsCVs8 from "/app/node_modules/.pnpm/nuxt-site-config@3.1.7_magicast@0.3.5_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_14enEAJnZaHOKBaAF5yI3BIOpbJMAOp6H4PS_RuB0Ew from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.17_db0@0.3.1_eslint@9.23.0_jiti@2.4_79fe5d09dd4163cece4df3e077d2b5a4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_Z6xc5syCQPKXZDzCKWH6yhFTXf5sXtaMTY1dy6fSrh0 from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.17_db0@0.3.1_eslint@9.23.0_jiti@2.4_79fe5d09dd4163cece4df3e077d2b5a4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_Vwb3BgaqDWCdEGXPMFJtYckVrsoM8LnMOAJTtscEZeU from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.17_db0@0.3.1_eslint@9.23.0_jiti@2.4_79fe5d09dd4163cece4df3e077d2b5a4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_WDPsh3FD0gQcw3fQCPi9BQVGuwwF_qaORqW8K86wGeg from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.17_db0@0.3.1_eslint@9.23.0_jiti@2.4_79fe5d09dd4163cece4df3e077d2b5a4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_fsWORruKmrcZLdOj8agn4VF2t_I0tKoWiPgN2cjwMz8 from "/app/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.8.2_vue@3.5.13_typescript@5.8.2__/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/app/services/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_w1NjzCpKU4mpkGop0ztoaoAIlf2MnsLwO_cSzflfby0 from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.17_db0@0.3.1_eslint@9.23.0_jiti@2.4_79fe5d09dd4163cece4df3e077d2b5a4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import siteConfig_cPbJFZHkoEFesqSPZnZrKIudsBuTD7vWtsfrY5H65PU from "/app/node_modules/.pnpm/nuxt-seo-utils@7.0.4_magicast@0.3.5_rollup@4.38.0_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_moVKxjNAMcAtS85uy5DZ9KPhsHujVAkvFbF1a7knAyc from "/app/node_modules/.pnpm/nuxt-seo-utils@7.0.4_magicast@0.3.5_rollup@4.38.0_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_Y62NKCTUehMhWXkSY38h3dxErDTXr2_OX0pFGZmITuo from "/app/node_modules/.pnpm/nuxt-seo-utils@7.0.4_magicast@0.3.5_rollup@4.38.0_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import defaultsWaitI18n_ZYJalwpvs2RJfsc8MQg4WiMsfuI_Iy7Q9bnn0wyGQAw from "/app/node_modules/.pnpm/nuxt-seo-utils@7.0.4_magicast@0.3.5_rollup@4.38.0_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaultsWaitI18n.js";
import defaults_z9RxHwD8BuwenaEAfa4TERjINjw61uJwIjtMFJLq9Ac from "/app/node_modules/.pnpm/nuxt-schema-org@5.0.4_magicast@0.3.5_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-schema-org/dist/runtime/app/plugins/i18n/defaults.js";
import i18n_vpNs8i0cr9N1X_z0gnT94vc4uwUE_tOHJq1wXR6Srws from "/app/node_modules/.pnpm/nuxt-site-config@3.1.7_magicast@0.3.5_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-site-config/dist/runtime/app/plugins/i18n.js";
import switch_locale_path_ssr_W7yPyzcqdvUy6Md2ThJCtLBYWDpuFWHujUBgJgNjA_g from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_ro_6ea16ffbe0833a8c062373776f583455/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_mI56YJ_NIMck5oJjMdYW4n5bC3XwJDNLmh3MFUfRnF4 from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_ro_6ea16ffbe0833a8c062373776f583455/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_hYbBbbuSEuLbR4hD_uJqmzjxn4JU7HZabd0sHsT_e9w from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_ro_6ea16ffbe0833a8c062373776f583455/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_76kqz087VHjqSLHktKUR3uBSQvM8FjTxQZRhqGaO3OU from "/app/node_modules/.pnpm/nuxt3-notifications@1.3.0_@nuxt+kit@3.16.2_magicast@0.3.5__vue@3.5.13_typescript@5.8.2_/node_modules/nuxt3-notifications/dist/runtime/plugin.mjs";
import api_u_4D19kXe6L_g5Txt7vY_LvcId7DroIS7qOojtIRCaw from "/app/services/common_fe/src/plugins/api.ts";
import capitalize_FhwZeS0bhY0BPmfM2FPxG5g6itDWXJff5XeYdNq4wes from "/app/services/common_fe/src/plugins/capitalize.ts";
import sentry_client_lRB3yrzh_0PDobM641aaFmBYSbCYp1yVDzvhtf7mARI from "/app/services/common_fe/src/plugins/sentry.client.ts";
import useEmitter_ly6OvGLh02xMoZy41LmftGiUhNNl675KCJPV_M2NzUg from "/app/services/common_fe/src/plugins/useEmitter.ts";
import vuetify_FAEMViqHg2jDHxyKTokLG7odpazo1_gHaCkY7CopZo0 from "/app/services/common_fe/src/plugins/vuetify.ts";
import _0_routeRules__7dh4kx8RA0LRhLPnEkeFXbgYPQ28CXk3weAelibkbE from "/app/node_modules/.pnpm/nuxt-seo-utils@7.0.4_magicast@0.3.5_rollup@4.38.0_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/0.routeRules.js";
import ssg_detect_u1cw11Qwa0znio3cqxzy7_7l6SzwloR26tUmENABpx8 from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_ro_6ea16ffbe0833a8c062373776f583455/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
import init_e06CoK4Y22JE8pC14cwkUTp__kVDP3HdW1XwmwHwVfs from "/app/node_modules/.pnpm/nuxt-schema-org@5.0.4_magicast@0.3.5_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-schema-org/dist/runtime/app/plugins/i18n/init.js";
export default [
  payload_plugin_l0I11Bt9_F06hAe1qG6_nrqSsvHyaGwuipYRvRr3oPA,
  revive_payload_client_UxWlz5y9wvp1gUTDNaVZlh3xGqCt_C2BaE_5DL_F6x0,
  unhead_jvMn9V86843ECJOzea8j_7ex_2KkTyMc6IWSosUTaHU,
  router_WzvjD5gTwmbF2KnmezE_fYaY6DPmgF8GP7_9rDADhZ4,
  _0_siteConfig_5_Df5MibenU9q7hOWAlwbNrMpfjqEZ03E35FowsCVs8,
  payload_client_14enEAJnZaHOKBaAF5yI3BIOpbJMAOp6H4PS_RuB0Ew,
  navigation_repaint_client_Z6xc5syCQPKXZDzCKWH6yhFTXf5sXtaMTY1dy6fSrh0,
  check_outdated_build_client_Vwb3BgaqDWCdEGXPMFJtYckVrsoM8LnMOAJTtscEZeU,
  chunk_reload_client_WDPsh3FD0gQcw3fQCPi9BQVGuwwF_qaORqW8K86wGeg,
  plugin_vue3_fsWORruKmrcZLdOj8agn4VF2t_I0tKoWiPgN2cjwMz8,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_w1NjzCpKU4mpkGop0ztoaoAIlf2MnsLwO_cSzflfby0,
  siteConfig_cPbJFZHkoEFesqSPZnZrKIudsBuTD7vWtsfrY5H65PU,
  inferSeoMetaPlugin_moVKxjNAMcAtS85uy5DZ9KPhsHujVAkvFbF1a7knAyc,
  titles_Y62NKCTUehMhWXkSY38h3dxErDTXr2_OX0pFGZmITuo,
  defaultsWaitI18n_ZYJalwpvs2RJfsc8MQg4WiMsfuI_Iy7Q9bnn0wyGQAw,
  defaults_z9RxHwD8BuwenaEAfa4TERjINjw61uJwIjtMFJLq9Ac,
  i18n_vpNs8i0cr9N1X_z0gnT94vc4uwUE_tOHJq1wXR6Srws,
  switch_locale_path_ssr_W7yPyzcqdvUy6Md2ThJCtLBYWDpuFWHujUBgJgNjA_g,
  route_locale_detect_mI56YJ_NIMck5oJjMdYW4n5bC3XwJDNLmh3MFUfRnF4,
  i18n_hYbBbbuSEuLbR4hD_uJqmzjxn4JU7HZabd0sHsT_e9w,
  plugin_76kqz087VHjqSLHktKUR3uBSQvM8FjTxQZRhqGaO3OU,
  api_u_4D19kXe6L_g5Txt7vY_LvcId7DroIS7qOojtIRCaw,
  capitalize_FhwZeS0bhY0BPmfM2FPxG5g6itDWXJff5XeYdNq4wes,
  sentry_client_lRB3yrzh_0PDobM641aaFmBYSbCYp1yVDzvhtf7mARI,
  useEmitter_ly6OvGLh02xMoZy41LmftGiUhNNl675KCJPV_M2NzUg,
  vuetify_FAEMViqHg2jDHxyKTokLG7odpazo1_gHaCkY7CopZo0,
  _0_routeRules__7dh4kx8RA0LRhLPnEkeFXbgYPQ28CXk3weAelibkbE,
  ssg_detect_u1cw11Qwa0znio3cqxzy7_7l6SzwloR26tUmENABpx8,
  init_e06CoK4Y22JE8pC14cwkUTp__kVDP3HdW1XwmwHwVfs
]